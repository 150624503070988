//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ["value", "onChange", "placeholder"],
  data: function data() {
    return {
      isEdit: false,
      val: this.value,
      isError: false
    };
  },
  methods: {
    onSave: function onSave() {
      if (!this.val) {
        this.isError = true;
        return;
      }

      this.isError = false;
      this.isEdit = false;
      this.onChange(this.val);
    }
  },
  watch: {
    value: function value(newVal) {
      this.val = newVal;
    }
  }
};