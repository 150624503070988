//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getPriceLogistics } from "@/config";
export default {
  props: ["list", "onSelect", "sumWeight"],
  methods: {
    priceLogistics: function priceLogistics(logistics) {
      var price = getPriceLogistics(logistics, {
        kg: this.sumWeight
      });
      return "\u0E3F".concat(price);
    },
    choose: function choose(item) {
      var _this = this;

      if (this.onSelect) {
        var price = getPriceLogistics(item, {
          kg: this.sumWeight
        });
        this.onSelect(item, price);
      }

      this.$nextTick(function () {
        _this.$bvModal.hide("logistics-modal");
      });
    },
    checkPush: function checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    }
  }
};