import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ["list", "onSelect"],
  methods: {
    choose: function choose(item) {
      var _this = this;

      if (this.onSelect) {
        this.onSelect(item);
      }

      this.$nextTick(function () {
        _this.$bvModal.hide("address-modal");
      });
    },
    renderAddress: function renderAddress(address) {
      var isBankok = address.province === "กรุงเทพมหานคร";
      var district = address.district && "".concat(isBankok ? "แขวง " : "ตำบล").concat(address.district);
      var amphoe = address.amphoe && "".concat(isBankok ? "เขต " : "อำเภอ").concat(address.amphoe);
      var province = address.province;
      var postcode = address.postcode && "".concat(address.postcode);
      var address1 = address.address;
      var phone = address.phone && "\u0E40\u0E1A\u0E2D\u0E23\u0E4C\u0E42\u0E17\u0E23 ".concat(address.phone);
      var arr = [];
      this.checkPush(arr, address1);
      this.checkPush(arr, district);
      this.checkPush(arr, amphoe);
      this.checkPush(arr, province);
      this.checkPush(arr, postcode);
      this.checkPush(arr, phone);
      return arr.join(", ");
    },
    checkPush: function checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    }
  }
};